.img-wrapper {
  column-count: 4;
  column-gap: 10px;
  counter-reset: count;
  width: 95%;
}

.img-wrapper > li {
  margin-bottom: 10px;
  list-style-type: none;
  break-inside: avoid-column;
}

#left {
  float: left;
  height: calc(100vh - var(--navBarHeight));
  overflow-y: scroll;
  text-align: center;
}

#right {
  float: left;
  overflow-y: scroll;
  height: calc(100vh - var(--navBarHeight));
  text-align: center;
}
