.image-uploader-container {
  width: 100%;
  height: 300px;
  border: 2px solid #aaa;
  border-radius: 10px;
}

.image-uploader {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  flex-direction: column;
  cursor: pointer;
}

.image-uploader input[type="file"] {
  display: none;
}

.image-preview {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.image-preview img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.upload-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.upload-message p {
  margin: 0;
  font-size: 14px;
  color: #888;
  text-align: center;
}
